import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Button, Footer, WhatsApp } from '~/components'
import * as st from '~/assets/styl/NotFound.module.styl'

const NotFound = ({ location }: PageProps) => (
  <>
    <Head
      location={location}
      title={`Mensagem enviada - ${process.env.GATSBY_SITE_NAME}`}
    />
    <section className={st.core}>
      <div className={st.container}>
        <div>
          <h3>Mensagem enviada</h3>
          <p>
            Sua mensagem foi enviada com sucesso.
            <br />
            Obrigado por entrar em contato com a Aikon.
          </p>
          <div className={st.links}>
            <Button href="/">Voltar</Button>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <WhatsApp />
  </>
)

export default NotFound
